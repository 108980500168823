<template>
    <div class="deck-content">
        <div class="content-header">
            <img height="64" src="/assets/images/title.mb.png" alt="">
        </div>
        <div class="content-body">
            <div class="template-list">

                <template v-for="(tpl,k) in templateList">

                    <div class="tpl" :key="k" @click=" selectedTpl = tpl " :class="{selected: tpl === selectedTpl }">
                        <img :src="tpl.template" alt="">
                    </div>


                </template>

            </div>
        </div>
        <div class="content-footer">
            <button class="btn submit-btn" style="margin-right: 10px" @click="confirmTpl">选择</button>
            <button class="btn submit-btn" @click="clearTpl">清空模板</button>
        </div>
    </div>
</template>

<script>
    import request from "../../../repo/request";

    export default {
        name: "DeckTemplate",
        data() {
            return {
                templateList: [],
                selectedTpl: null,
            }
        },
        mounted() {
            this.getTemplates()
        },
        methods: {
            clearTpl() {
                this.$emit('selected', '')
            },
            confirmTpl() {
                if (!this.selectedTpl) return

                this.$emit('selected', this.selectedTpl.template)
            },
            getTemplates() {
                request.get('/collocation/scheme/templates', {}, (data) => {
                    this.templateList = data.data
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .deck-content {
        height: 100vh;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
    }

    .content-header {
        padding: 0 32px;

        height: 70px;

        .cn {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            margin-bottom: 8px;
        }

        .en {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
        }
    }

    .content-body {
        flex: 1;
        overflow-y: auto;
        padding: 25px 32px;
    }

    .content-footer {
        height: 96px;
        padding: 0 32px;
    }

    .submit-btn {
        margin-top: 24px;
        padding: 5px 27px;
    }

    .item-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 16px;
    }

    input, textarea {
        border: 1px solid #333333;
        padding: 5px 12px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
    }

    textarea {
        padding: 12px;

    }

    .item-creator {
        float: left;
    }

    .content-item {
        margin-bottom: 32px;
        overflow: hidden;
    }

    .count {
        position: absolute;
        right: 12px;
        top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 22px;
    }

    .summary-count {
        top: 61px;
    }


    .item-content {
        position: relative;
    }

    .item-list {
        .item-content {
            margin-right: -16px;
            overflow: hidden;
        }

        margin-bottom: 16px;
    }

    .item-btn {
        padding: 5px 16px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        background-color: white;
        line-height: 20px;
        margin-right: 16px;
        margin-bottom: 16px;
        float: left;
        user-select: none;

        &.selected {
            color: #FFFFFF;
            background-color: rgba(51, 51, 51, 1);
        }
    }

    .add-btn {
        width: 32px;
        height: 32px;
        font-size: 12px;
        padding: 0;
    }

    .add-input {
        width: 80px;
    }

    .op-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-left: 8px;
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .template-list {
        /*display: flex;*/
        /*justify-content: left;*/
        /*flex-direction: row;*/
        overflow: hidden;
    }

    .tpl {
        width: 25%;
        padding: 10px;
        border: 1px solid transparent;
        cursor: pointer;
        float: left;
        margin-bottom: 12px;

        &.selected {
            border-color: #333333;
        }

        img {
            width: 100%;
        }
    }

</style>
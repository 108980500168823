<template>
    <div class="deck-content">
        <div class="content-header">
            <img height="64" :src="titleImage" alt="">
        </div>
        <div class="content-body">

            <div class="content-item">

                <div class="item-title">*搭配标题：</div>
                <div class="item-content">
                    <input v-model="data.title" @keyup="titleChanged" type="text" maxlength="20">
                    <span class="count">
                        <span :class="{'red' : titleLength >= 20} ">{{ titleLength }}</span>/20</span>
                </div>

            </div>

            <div class="content-item">

                <div class="item-title">*搭配说明：</div>
                <div class="item-content">
                    <textarea v-model="data.summary" @keyup="summaryChanged" rows="3" maxlength="120"></textarea>
                    <span class="count summary-count">
                        <span :class="{'red' : summaryLength >= 120} ">{{ summaryLength }}</span>/120</span>
                </div>

            </div>

            <div class="content-item item-list">

                <div class="item-title">*适宜性别：</div>
                <div class="item-content">
                    <button class="btn item-btn" :class="{selected:data.gender === 1 }" @click="selectGender(1)">男士
                    </button>
                    <button class="btn item-btn" :class="{selected:data.gender === 2 }" @click="selectGender(2)">女士
                    </button>
                </div>
            </div>


            <template v-if="options.length && data.gender">

                <template v-for="(picker,k) in pickers">

                    <div class="content-item item-list" :key="k">

                        <div class="item-title">*{{ picker.name }}：</div>

                        <div class="item-content">

                            <template v-if="picker.type === 'topics' ">


                                <div class="item-creator">

                                    <input placeholder="在这里输入你感兴趣的话题"
                                           v-model="editInputValue"
                                           @keyup="getTopics"
                                           class="add-input"/>

                                    <div v-if="topicSelectList.length" class="selected-list">

                                        <template v-for="(keyword,idx) in topicSelectList">
                                            <p :key="idx" @click="topicConfirm(picker,keyword)">{{ keyword }}</p>
                                        </template>
                                    </div>
                                </div>


                            </template>

                            <template v-for="(item,ik) in picker.items ">

                                <button :key="ik"
                                        @click="itemBtnClicked(picker,item)"
                                        class="btn item-btn"
                                        :class="{'selected': item.selected }"
                                >{{ item.name }}
                                </button>

                            </template>

                        </div>
                    </div>


                </template>

            </template>

        </div>
        <div class="content-footer">
            <button @click="submitContent" class="btn submit-btn">提交</button>
        </div>
    </div>
</template>

<script>
    import request from '@/repo/request';

    export default {
        name: "DeckContent",
        props: {
            titleImage: String,
            outfit: Object
        },
        watch: {
            outfit() {
                this.loadOutfit()
            }
        },
        data() {
            return {
                topicSelectList: [],
                titleLength: 0,
                summaryLength: 0,
                editInputValue: '',
                data: {
                    title: '',
                    summary: '',
                    gender: 0,
                    occasion: [],
                    atmosphere: [],
                    season: [],
                    styles: [],
                    topics: [],
                },
                options: [],
                pickers: [
                    {
                        id: 5,
                        name: '适宜着装场合',
                        items: [],
                        type: 'occasion',
                        value: 'id',
                        max: 2,
                    },
                    {
                        id: 8,
                        name: '搭配风格氛围',
                        items: [],
                        type: 'atmosphere',
                        value: 'id',
                        max: 2,
                    },
                    {
                        id: 7,
                        name: '适宜季节',
                        items: [],
                        type: 'season',
                        value: 'id',
                        max: 2,
                    },
                    {
                        id: 10010,
                        name: '话题',
                        items: [],
                        type: 'topics',
                        value: 'name',
                        max: 5,
                    },
                    {
                        id: 10086,
                        name: '适宜人群类型',
                        items: [],
                        type: 'styles',
                        value: 'id',
                        max: 3,
                    }
                ]
            }
        },
        mounted() {
            this.fetchOptions()
            this.loadOutfit()
        },
        methods: {
            loadOutfit() {

                if (!this.outfit) return;
                let outfit = this.outfit
                this.data.title = outfit.title
                this.data.summary = outfit.summary
                this.data.gender = outfit.gender

                this.genderChanged()
                this.loadOutfitOptions()
            },


            titleChanged() {
                this.titleLength = ('' + this.data.title).length
            },
            summaryChanged() {
                this.summaryLength = ('' + this.data.summary).length
            },
            selectGender(gender) {
                this.data.gender = gender
                this.genderChanged()
            },
            genderChanged() {
                if (!this.data.gender) return

                this.pickers.forEach(picker => {

                    let items = []

                    this.options.forEach(item => {

                        let gender = +item.gender

                        if (gender !== this.data.gender && gender !== 0) return

                        if (item.parent_id !== picker.id) return

                        items.push(item)
                    })

                    picker.items = items
                })
            },
            fetchOptions() {

                request.get('/collocation/options/all', {}, (data) => {
                    this.options = data.data
                    this.genderChanged()
                    this.loadOutfitOptions()
                });
            },
            getTopics() {

                if (!this.editInputValue) return

                request.get('/collocation/scheme/topics', {
                    keywords: this.editInputValue,
                    gender: this.data.gender
                }, (data) => {

                    let list = []

                    if (data.data) {

                        data.data.forEach(item => {

                            list.push(item.name)

                        })

                        if (list.indexOf(this.editInputValue) < 0) {

                            list.unshift(this.editInputValue)
                        }
                    }

                    this.topicSelectList = list

                });
            },
            loadOutfitOptions() {

                if (!this.outfit) return;
                if (!this.options.length) return;

                this.pickers.forEach(picker => {

                    let otItems = this.outfit[picker['type']]

                    if (!otItems || !otItems.length) return


                    otItems.forEach(otem => {

                        let inItems = false;

                        picker.items.forEach(item => {

                            if (item[picker.value] !== otem[picker.value]) return

                            inItems = true

                            this.itemBtnClicked(picker, item)
                        })

                        if (picker.type === 'topics' && !inItems) {

                            picker.items.push(otem);
                            this.itemBtnClicked(picker, otem)
                        }

                    })

                });

            },
            itemBtnClicked(picker, item) {

                let data = this.data[picker.type]
                let value = item[picker.value]

                let idx = data.indexOf(value)

                if (idx >= 0) {

                    data.splice(idx, 1)

                    item.selected = false

                } else {

                    let selectedCount = 0

                    picker.items.forEach(item => {
                        if (item.selected) selectedCount++
                    })

                    if (selectedCount >= picker.max) return

                    data.push(value)
                    item.selected = true
                }


                this.$forceUpdate()
            },
            topicConfirm(picker, val) {

                let exit = false

                picker.items.forEach(im => {

                    if (im.name !== val) return

                    if (!im.selected) {
                        this.itemBtnClicked(picker, im)
                    }

                    exit = true
                })

                if (!exit) {

                    let item = {
                        name: val,
                        id: val
                    }

                    picker.items.push(item)

                    this.itemBtnClicked(picker, item)
                }


                this.editInputValue = ''
                this.topicSelectList = []
            },
            showError(msg) {
                this.$swal({
                    title: msg,
                    icon: 'error',
                    confirmButtonText: '好'
                });
            },
            submitContent() {
                if (!this.data.title) {
                    return this.showError('搭配标题不能为空');
                }
                if (!this.data.summary) {
                    return this.showError('搭配说明不能为空');
                }

                if (!this.data.gender) {
                    return this.showError('请选择适宜性别');
                }

                for (let pickersKey in this.pickers) {

                    let picker = this.pickers[pickersKey]

                    let items = picker.items;

                    let selected = false

                    items.forEach(item => {
                        if (item.selected) selected = true
                    })

                    if (selected) continue;


                    return this.showError(`请选择${picker.name}`)

                }

                let data = JSON.parse(JSON.stringify(this.data));

                this.$emit('submitContent', data);
            }
        }
    }
</script>

<style lang="less" scoped>
    .deck-content {
        height: 100vh;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
    }

    .content-header {
        padding: 0 32px;

        height: 70px;

        .cn {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            margin-bottom: 8px;
        }

        .en {
            font-size: 14px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 28px;
        }
    }

    .content-body {
        flex: 1;
        overflow-y: auto;
        padding: 25px 32px;
    }

    .content-footer {
        height: 96px;
        padding: 0 32px;
    }

    .submit-btn {
        margin-top: 24px;
        padding: 5px 27px;
    }

    .item-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 16px;
    }

    input, textarea {
        border: 1px solid #333333;
        padding: 5px 12px;
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
    }

    textarea {
        padding: 12px;

    }

    .item-creator {
        padding-right: 16px;
        margin-bottom: 10px;
        position: relative;
        z-index: 2;

        .selected-list {

            background-color: white;
            position: absolute;
            left: 0;
            top: 33px;
            right: 16px;
            padding: 5px 12px;
            z-index: 2;


            border: 1px solid #333333;

            p {
                margin-bottom: 5px;
                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .content-item {
        margin-bottom: 32px;
    }

    .count {
        position: absolute;
        right: 12px;
        top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 22px;
    }

    .summary-count {
        top: 61px;
    }


    .item-content {
        position: relative;
    }

    .item-list {
        .item-content {
            margin-right: -16px;
            /*overflow: hidden;*/
        }

        margin-bottom: 16px;
    }

    .item-btn {
        padding: 5px 16px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        background-color: white;
        line-height: 20px;
        margin-right: 16px;
        margin-bottom: 16px;
        user-select: none;

        &.selected {
            color: #FFFFFF;
            background-color: rgba(51, 51, 51, 1);
        }
    }

    .add-btn {
        width: 32px;
        height: 32px;
        font-size: 12px;
        padding: 0;
    }

    .add-input {
        width: 100%;
        outline: inherit;
    }

    .op-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        margin-left: 8px;
        font-size: 12px;
    }

    .red {
        color: red;
    }

</style>